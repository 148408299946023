//Actions
import { resetDataSaleReducer, setDataSaleReducer, setDataSalesStateReducer, setStateSaleReducer } from '../../../storage/reducers/sales/sales.action';

//Assets
import { Assets } from '../../../assets'

//Components
import NavigationBodyComponent from '../components/navigation-body/navigation-body.component';
import StepperComponent from '../components/stepper/stepper.component';
import CustomerDataComponent from './components/customer-data/customer-data.component';
import RecipientsDataComponent from './components/recipients-data/recipients-data.component';
import DrawerComponent from "../components/drawer/drawer.component"
import CostAndInvoicingComponent from './components/cost-and-invoicing/cost-and-invoicing.component';
import PreviewPdfComponent from '../components/preview-pdf/preview-pdf.component';
import PdfTurnComponent from './components/pdf-turn/pdf-turn.component';
import ModalSaveComponent from '../components/modal-save/modal-save.component';
import ModalInvoiceComponent from '../components/modal-invoice/modal-invoice.component';
import ErrorToastComponent from '../../../shared/components/toast/error-toast/error-toast.component';

//Components - Shared
import ModalMainComponent from '../../../shared/components/modal/modal-main/modal-main.component';
import SuccessToastComponent from '../../../shared/components/toast/success-toast/success-toast.component';

//Constants
import AlertConst from '../../../core/constants/alerts.const';
import { SalesErrorsConst } from '../../../core/constants/errors/alerts-app/sales.errors.const';

// Connect WebSocket
import { PrintConnectionSocket } from '../../../socket';

//Libraries
import { Spin } from 'antd';
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom';
// import printJS from 'print-js';
// import { pdf } from '@react-pdf/renderer';

//Services
import { createClientService, updateClientService } from '../../../services/shared.services';
import { createInvoiceTurnService, getInvoiceTurnService } from '../../../services/sales.services';

//Styles
import "./turn.page.scss"

const TurnPage = (props) => {

  const { 
    // Actions
    resetDataSaleReducer,
    setStateSaleReducer,
    setDataSalesStateReducer,
    setDataSaleReducer,
    // Variables
    isShowDrawer,
    turnSales,
    innerWidth
  } = props;
  const [t] = useTranslation("translation")

  const INITIAL_STATE = {
    locationStepperState: 0,
    isModalInvoice: false,
    isModalSave: false,
    isOpenModal: false,
    invoiceData: null,
    typePrint: 0,
    isLoadingModalSpin: false
  };

  const [state, setState] = useState(INITIAL_STATE);
  const { 
    locationStepperState, isModalInvoice, isModalSave, 
    isOpenModal, invoiceData, typePrint, isLoadingModalSpin
  } = state;

  const steps = [
    { label: "Datos del Cliente" },
    { label: "Datos del Destinatario" },
    { label: "Costo y facturación del giro"}
  ];

  let history = useNavigate();
  const nextButtonRef = useRef();
  const invoiceButtonRef = useRef();

  const directSale = () => {
    resetDataSaleReducer();
    history("/sales", { replace: true });
  };

  const handleInvoice = () =>{
    if (isModalInvoice) {
      setStateSaleReducer('isShowDrawer', true)
    } else {
      setState({
        ...state,
        isModalSave: false,
        isOpenModal:true
      })
    }
    
  }

  const handleSave = () =>{
    setState({
      ...state,
      isModalSave: true,
      isOpenModal:true
    })
  }

  const handleAcceptInvoice = async () => {
    try {
      if (isModalSave) {
        SuccessToastComponent({
          html:
            `<span>
            ${t("sales.salesFooter.congratulations")}
          </span>`,
          position: AlertConst.TOP_END_POSITION_TEXT,
          timer: 5000
        });

        setState((prevState) => ({
          ...prevState,
          isOpenModal: false,
          isLoadingModalSpin: false
        }));
      } else {
        await createInvoice(turnSales);
        await updateClient();

        setState((prevState) => ({
          ...prevState,
          isLoadingModalSpin: false
        }));
      }
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        isLoadingModalSpin: false
      }));
    }
  };

  const createInvoice = async({
    idCustomer,idRecipients,dinner,
    dinnerCost,dinnerIva
  }) =>{
    try {
      const idInvoice = await createInvoiceTurnService(
        {
          idClientSends: idCustomer,
          idClientReceives: idRecipients,
          amountMoney: dinner,
          cost:dinnerCost,
          iva:dinnerIva,
          isElectronic: turnSales.isElectronic,
          idSite: turnSales?.municipalityResidenceRecipients?.value,
          costTransferRange: turnSales.costTransferRange,
          ivaPercentageRange: parseFloat(turnSales.ivaPercentageRange)
        }
      )
      await setStateSaleReducer("idInvoice", idInvoice)
      SuccessToastComponent({
        html: 
        `<span>${t("sales.passages.successfully")}
          <a class="turn__text-alert" href="/sales">${t("sales.passages.salesHistory")}</a>
        </span>`,
        position: AlertConst.TOP_END_POSITION_TEXT,
        timer: 5000
      })
      setStateSaleReducer('isDisabled', true)
      setState({
        ...state,
        locationStepperState: locationStepperState + 1,
        isModalInvoice: true,
        isOpenModal:false
      })
      getInvoice(idInvoice);
    } catch (err) {
      ErrorToastComponent({
        title: SalesErrorsConst.moneyTransfer[err.code] || `${SalesErrorsConst.default} (${err.code || 'UDFND'})`,
        position: AlertConst.TOP_END_POSITION_TEXT,
        timer: 1500
      })
    }
  }

  const getInvoice = async(idInvoice) => {
    try {
      const { connectSocket, printTicketSocket } = PrintConnectionSocket();
      connectSocket();
      const dataInvoice = await getInvoiceTurnService(idInvoice);

      setState((prevState)=>({
        ...prevState,
        invoiceData: dataInvoice,
      }));

      setStateSaleReducer('isShowDrawer', true);

      printTicketSocket({typeTicket: 'printTurns', data: dataInvoice});
    } catch {
      // TODO: Implement error alert with code error.
    }
  }

  const updateClient = async () => {
    const compareAndUpdate = async (keysToCompare, typeClient) => {
      const compareData = keysToCompare.every(key => {
        const turnSalesValue = turnSales[`${key}${typeClient}`];
        const originalClientDataValue = turnSales[`originalClientData${typeClient}`][`${key}${typeClient}`];
        return deepEqual(turnSalesValue, originalClientDataValue);
      });
  
      if (compareData === false) {
        try {
          const dataClient = Object.keys(turnSales).reduce((object, key) => {
            const newKey = key.replace(`${typeClient}`, '');
            object[newKey] = turnSales[key];
            return object;
          }, {});
  
          await updateClientService(dataClient);
          setDataSalesStateReducer('turnSales', `originalClientData${typeClient}`, turnSales);
        } catch {
          // TODO: Implement error alert with code error.
        }
      }
    };
  
    const customerKeys = [
      "documentType",
      "documentNumber",
      "name",
      "lastName"
    ];
    await compareAndUpdate(customerKeys, "Recipients");

  };
  const handleNext = () =>{
    setStateSaleReducer('isShowDrawer', false)
    if (steps.length > locationStepperState) {
      (locationStepperState === 0 || locationStepperState === 1 )&& validateClient()
      setState({
        ...state,
        locationStepperState: locationStepperState + 1,
      });
    }
    if (steps.length === locationStepperState) {
      setState({
        ...state,
        isOpenModal:false
      })
      resetDataSaleReducer()
      history("/sales", { replace: true });
    }
  }

  const validateClient = async () =>{
    let typeClient= ""
    locationStepperState === 0 && (typeClient = "Customer")
    locationStepperState === 1 && (typeClient = "Recipients")
    if ((locationStepperState === 0 && turnSales?.idCustomer) || (locationStepperState === 1 && turnSales?.idRecipients)) {
      const keysToCompare = [
        "id",
        "documentType",
        "documentNumber",
        "name",
        "lastName",
        "telephone",
        "telephoneCode",
        "whatsapp",
        "whatsappCode",
        "departmentResidence",
        "municipalityResidence",
        "contactAddress",
        "contactEmail" 
      ];

      const compareData = keysToCompare.every(key => {
        const turnSalesValue = turnSales[`${key}${typeClient}`];
        const originalClientDataValue = turnSales[`originalClientData${typeClient}`][`${key}${typeClient}`];
        return deepEqual(turnSalesValue, originalClientDataValue);
      });

      if (compareData === false) {
        try {
          const dataClient = Object.keys(turnSales).reduce((object, key) => {
            const newKey = key.replace(`${typeClient}`, '');
            object[newKey] = turnSales[key];
            return object;
          }, {});
          await updateClientService(dataClient);
          setDataSalesStateReducer('turnSales', `originalClientData${typeClient}` , turnSales)
        } catch {
          // TODO: Implement error alert with code error.
        }
      }
    }else{
      try {
        const newClient = Object.keys(turnSales).reduce((object, key) => {
          const newKey = key.replace(`${typeClient}`, '');
          object[newKey] = turnSales[key];
          return object;
        }, {});
        const idClient = await createClientService(newClient);
        let dataClient = turnSales
        dataClient[`id${typeClient}`] = idClient
        dataClient[`originalClientData${typeClient}`] = {...dataClient}
        setDataSaleReducer("turnSales",dataClient)
      } catch {
        // TODO: Implement error alert with code error.
      }
      
    }
  }

  const deepEqual = (value1, value2) => {
    if (typeof value1 === "object" && typeof value2 === "object") {
      const keys1 = Object.keys(value1);
      const keys2 = Object.keys(value2);
  
      if (keys1.length !== keys2.length) {
        return false;
      }
  
      for (const key of keys1) {
        if (!deepEqual(value1[key], value2[key])) {
          return false;
        }
      }
  
      return true;
    }
  
    return value1 === value2;
  }

  const handleCloseModal = () =>{
    setState({
      ...state,
      isOpenModal: false
    })
  }

  const handleBack = () =>{
    setStateSaleReducer("isShowDrawer", false);
    if (locationStepperState > 0) {
      setState({
        ...state,
        locationStepperState: locationStepperState - 1,
      });
    }
  }

  const closeDrawer = () => {
    setStateSaleReducer("isShowDrawer", false);
  }

  const pdfDocumentPrint = (size) =>{
    const { connectSocket, printTicketSocket } = PrintConnectionSocket();
    connectSocket();
    printTicketSocket({typeTicket: 'printTurns', data: invoiceData});
    
    // const pdfBlob = size ? pdf(<PdfTurnComponent dataInvoice={invoiceData} size />) : pdf(<PdfTurnComponent dataInvoice={invoiceData} />);
    // pdfBlob.toBlob().then((blob) => {
    //   printPdf(URL.createObjectURL(blob))
    // });
  }

  // const printPdf = async(url) => {
  //   if (innerWidth > 639) {
  //     printJS({
  //       printable: url,
  //       type: "pdf",
  //       showModal: true,
  //     })
  //   } else {
  //     window.open(url)
  //   }
  // };

  const handlePrint = () =>{
    const pdfSize = innerWidth < 639 ? undefined : typePrint
    pdfDocumentPrint(pdfSize)
  }
  
  return (
    <>
    <div className="turn__content">
      <span className="turn__subtitle">{t("sales.passages.sales")}</span>
      <img src={Assets.SharedIcons.icon_next} alt="icon_next" />
      <span className="turn__subtitle">
        {t("sales.turnPage.title")}
      </span>
    </div>
    <div className={`grid-x ${isShowDrawer ? "turn__container--mod" : "turn__container"}`}>
      <div className={`${isShowDrawer?"turn__container__steps--mod":"turn__container__steps"} grid-x`}>
      <StepperComponent
        title={t("sales.turnPage.sending")}
        name={t("sales.turnPage.title")}
        icon={Assets.SalesIcons.icon_turns}
        dataStepper={steps}
        locationStepper={locationStepperState}
        onClose={()=>directSale()}
        onStepper={(e)=>{
          setState({
            ...state,
            locationStepperState: e,
          })
        }}
      />
      <NavigationBodyComponent
        nextButtonRef={nextButtonRef}
        invoiceButtonRef={invoiceButtonRef}
        locationStepper={locationStepperState}
        onBack={()=>handleBack()}
        onInvoice={()=>handleInvoice()}
        onSave={()=>handleSave()}
        onNext={()=>handleNext()}
        textSave={t("sales.passages.save")}
        textNext={locationStepperState >= 3 ? t("sales.passages.end") : t("sales.passages.next")}
        textInvoice={isModalInvoice ? t("sales.passages.seeInvoice") : t("sales.passages.invoice")}
        numStepper={steps.length}
      >
        {locationStepperState === 0 && <CustomerDataComponent nextButtonRef={nextButtonRef}/>}
        {locationStepperState === 1 && <RecipientsDataComponent nextButtonRef={nextButtonRef}/>}
        {locationStepperState >= 2 && <CostAndInvoicingComponent invoiceButtonRef={invoiceButtonRef}/>}
      </NavigationBodyComponent>
    </div>
      <div className={isShowDrawer ? "turn__drawer-modal--active" : "turn__drawer-modal"}>
        { isShowDrawer &&
          <DrawerComponent 
            pdfDocument={<PdfTurnComponent dataInvoice={invoiceData}/>}
            onClose = {()=> closeDrawer()}
            title={t("sales.turnPage.salesInvoice")}
            onModalPrint={()=>handlePrint()}
            typePrint={typePrint}
            // onTypePrint={(type)=> setState({ ...state, typePrint: type })}
          >
            <PreviewPdfComponent>
              <PdfTurnComponent dataInvoice={invoiceData}/>
            </PreviewPdfComponent>
          </DrawerComponent>
        }
      </div>
    </div>
      <>
        <ModalMainComponent
          width={300}
          open={isOpenModal}
          onClose={()=>handleCloseModal()}
          footer={[
            <button key="accept" className="turn__modal__button"
                onClick={()=> {
                  setState((prevState) => ({ ...prevState, isLoadingModalSpin: true }));
                  handleAcceptInvoice();
                }}
                disabled={isLoadingModalSpin}
              >
                <span className="turn__modal__button__label">

                {isLoadingModalSpin ? <Spin size='default' className='turn__modal__spin' /> : (isModalSave ? t("sales.passages.accept") : t("sales.passages.invoiceNow"))}
                </span>
              </button>,
              <div className={`turn__modal__close ${isModalSave ? "turn__hidden" : ""}`} key="cancel">
                <button
                  className="turn__modal__close__label"
                  onClick={()=>
                    handleCloseModal()
                  }
                >
                  {t("sales.passages.cancel")}
                </button>
              </div>
          ]}
        >
          {isModalSave ?
            <ModalSaveComponent/>
           : 
            <ModalInvoiceComponent/>
          }
        </ModalMainComponent>
      </>
  </>
  )
}

const mapStateToProps = ({ SalesReducer, AppReducer }) => {
  const { isShowDrawer, turnSales } = SalesReducer;
  const { windowSize: { innerWidth } } = AppReducer;
  return {
    isShowDrawer,
    turnSales,
    innerWidth
  };
};


const mapStateToPropsActions = {
  resetDataSaleReducer,
  setStateSaleReducer,
  setDataSalesStateReducer,
  setDataSaleReducer
};

export default connect(mapStateToProps, mapStateToPropsActions)(TurnPage);