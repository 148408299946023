//Assets
import { Assets } from '../../assets'

//Components
import ModalGenerateComponent from './components/modal-generate/modal-generate.component'
import ModalTransferComponent from './components/modal-transfer/modal-transfer.component'
import ModalDetailsComponent from './components/modal-details/modal-details.component'
import PdfDetailsComponent from './components/pdf-details/pdf-details.component'
import ModalPreviewComponent from './components/modal-preview/modal-preview.component'
import ModalTemporaryPayrollComponent from './components/modal-temporary-payroll/modal-temporary-payroll.component'


//Components-Shared
import ModalMainComponent from '../../shared/components/modal/modal-main/modal-main.component'
import ErrorToastComponent from '../../shared/components/toast/error-toast/error-toast.component'
import SuccessToastComponent from '../../shared/components/toast/success-toast/success-toast.component'

// Connect WebSocket
import { PrintConnectionSocket } from '../../socket'

//Constants
import AppConst from '../../core/constants/app.const'
import { BoardingSheetErrorsConst } from '../../core/constants/errors/alerts-app/boarding-sheet.errors.const'
import AlertConst from '../../core/constants/alerts.const'

//Libraries
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Pagination, PaginationItem } from '@mui/material'
import { DatePicker, Input, Spin } from 'antd'
import dayjs from 'dayjs';
import { BlobProvider, pdf } from '@react-pdf/renderer'
import { connect } from 'react-redux'

//Services
import { 
  createManifestNumberService,
  getAllManifestTravelCountService, getAllManifestTravelService, updateAssignTravelAnotherVehicleService, 
  getManifestByIdService, 
  getManifestTravelByDateService, getOrderWithGuideService, getDataAllPdfService, postManifestTravelByRangeDateService,
  getTemporaryPayrollService,
  updateTemporaryPayrollService,
  getDetailsTemporaryPayrollService,
  getHeadquarterAssociatedService,
  deletePayrollService,
  patchPassagesListingService
} from '../../services/boarding-sheet.services'
import { getUserService } from '../../services/authentication.services'

//Styles
import './boarding-sheet.page.scss';

//Utils
import { boardingSheetExcelUtils } from '../../utils/boarding-sheet.utils'
import BoardingSheetConst from '../../core/constants/boarding-sheet.const'
import InfoToastComponent from '../../shared/components/toast/info-toast/info-toast.component'

const BoardingSheetPage = (props) => {
const {
  optionsShipping,
  optionsVehicleSearch
} = props
  

  const { t } = useTranslation()
  const headerTableFilter = [
    "Nro. de planilla",
    "Código del vehículo",
    "Nro. del vehículo",
    "Nombre del conductor",
    "Ruta",
    "Fecha de salida",
    ""
  ]


  const INITIAL_STATE = {
    isOpenModal: false,
    isGenerate: false,
    weekSelected: dayjs(),
    listGenerator: [],
    isFirst: false,
    listBoardingSheet: [],
    textFilter: "",
    countPage: 0,
    pageSelected: 1,
    isViewDetails: false,
    itemSelected: null,
    driverSelected: null,
    vehicleSelected: null,
    itemPreview: null,
    observations: "",
    dataGeneratePayroll: null,
    selectedShipments: [],
    isPartialAssignmentsModal: false,
    vehicleAvailable: null,
    isDisabledModalPreview: true,
    initialDateRange: null,
    endDateRange: null,
    showButton: false,
    isTemporaryPayroll: false,
    selectedPassages: [],
    dataPreview: null,
    headquarterAssociated: null,
    isAlertModal: false,
    isPayrollAdministrator: false,
    isLoadingSpin: false,
    isLoadingModalSpin: false,
    isTransferPassages: false,
    dateSelected: dayjs()
  }

  const [state, setState] = useState(INITIAL_STATE)
  const { 
    isOpenModal, isGenerate, weekSelected, 
    listGenerator, isFirst, listBoardingSheet,
    textFilter, countPage, pageSelected,
    isViewDetails, itemSelected, driverSelected,
    vehicleSelected, itemPreview, observations, 
    selectedShipments, isPartialAssignmentsModal,
    isDisabledModalPreview, initialDateRange,
    endDateRange, showButton, isTemporaryPayroll,
    selectedPassages, dataPreview, headquarterAssociated,
    isAlertModal, isPayrollAdministrator, isLoadingSpin, isLoadingModalSpin,
    isTransferPassages, dateSelected
  } = state

  useEffect(() => {

    const services = [getManifestTravelByDateService(dayjs(weekSelected).format('YYYY-MM-DD')), getHeadquarterAssociatedService()]
    if (!isFirst) {
      services.push(
        getAllManifestTravelService(textFilter, 0),
        getAllManifestTravelCountService(textFilter)
      )
    }
    payrollServices(services)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weekSelected])

  const payrollServices = async (services) => {
    await Promise.allSettled(services)
    .then((res)=>{
      let generatorList = [];
      let boardingSheetList = [];
      let countPage = 0;
      let headquarterAssociated = null;

      for (let index = 0; index < res.length; index++) {
        const elementResponse = res[index];

        if (elementResponse.status === AppConst.FULFILLED) {
          switch (index) {
            case 0:
              generatorList = elementResponse?.value
              break;
            case 1:
              headquarterAssociated = elementResponse?.value?.name
              break;
            case 2:
              boardingSheetList = elementResponse?.value
              break;
            case 3:
              countPage = elementResponse?.value
              break;
            default:
              break;
          }
        }
      }
      const { hasPostPayroll } = getUserService()

      setState((prevState) => ({
        ...prevState,
        listGenerator: generatorList,
        isFirst : true,
        listBoardingSheet: isFirst ? prevState.listBoardingSheet : boardingSheetList,
        countPage: isFirst ? prevState.countPage : countPage,
        headquarterAssociated: isFirst ? prevState.headquarterAssociated : headquarterAssociated,
        isPayrollAdministrator: hasPostPayroll  ? true : false,
        isReload: false
      }))
    })
    .catch(() => {
      // TODO: Implement error alert with code error.
    })
  }

  const onChange = (data, target) => {
    if (target === "rangePicker") {
      setState((prevState) => ({
        ...prevState,
        initialDateRange: data[0],
        endDateRange: data[1],
        showButton:false
      }))
    } else {
      const value = data && data.target ? data.target.value || '' : data;
      setState((prevState) => ({
        ...prevState,
        [target]: value
      }))
    }
  };

  const handleWeek = (value) => {
    setState({
      ...state,
      weekSelected: value
    })
  }

  const previousWeek = () => {
    const week = weekSelected.subtract(1, 'day')
    setState({
      ...state,
      weekSelected: week
    })
  }

  const nextWeek = () => {
    const week = weekSelected.add(1, 'day')
    setState({
      ...state,
      weekSelected: week
    })
  };

  const handleSearch = async () => {
    await Promise.allSettled([
      getAllManifestTravelService(textFilter, 0, initialDateRange, endDateRange),
      getAllManifestTravelCountService(textFilter, initialDateRange, endDateRange)
    ])
    .then((res)=>{
      let boardingSheetList = [];
      let countPage = 0

      for (let index = 0; index < res.length; index++) {
        const elementResponse = res[index];

        if (elementResponse.status === AppConst.FULFILLED) {
          switch (index) {
            case 0:
              boardingSheetList = elementResponse?.value
              break;
            case 1:
              countPage = elementResponse?.value
              break;
            default:
              break;
          }
        }
      }

      setState((prevState) => ({
        ...prevState,
        listBoardingSheet: boardingSheetList,
        countPage: countPage,
        showButton: boardingSheetList.length > 0 && initialDateRange && endDateRange,
        pageSelected: 1
      }))
    })
    .catch(() => {
      // TODO: Implement error alert with code error.
    })
  };

  const handleAccounting = async (isValidateSynchronization) => {
    try {
      const response = await postManifestTravelByRangeDateService(initialDateRange, endDateRange,isValidateSynchronization)

      setState((prevState) => ({
        ...prevState,
        isAlertModal: false,
        isLoadingSpin: false,
        isLoadingModalSpin: false
      }))

      const { completed, failure } = response
      
      if (completed.length === 0 && failure.length === 0) {
        SuccessToastComponent({
          html: 
          `<span>No hubo cambios contables para registrar de las planillas en el rango de fechas
          <a class="boarding-sheet__text-alert" </a>
          </span>`,
          position: AlertConst.TOP_END_POSITION_TEXT,
          timer: 5000
        })  
    } else if (completed.length > 0 && failure.length === 0) {
        SuccessToastComponent({
          html: 
          `<span>Se ha contabilizado correctamente la planilla
          <a class="boarding-sheet__text-alert" </a>
          </span>`,
          position: AlertConst.TOP_END_POSITION_TEXT,
          timer: 7000
        })  
    } else if (failure.length > 0 && completed.length === 0) {
        ErrorToastComponent({
          title: "No se pudo contabilizar las siguientes fechas",
          position: AlertConst.TOP_END_POSITION_TEXT,
          timer: 7000,
          html: failure.map(({date, headquarter}) => `${date} - ${headquarter}`).join("<br/>")
        })
    } else {
        InfoToastComponent({
          position: AlertConst.TOP_END_POSITION_TEXT,
          timer: 7000,
          html: 
          `<span class="class="boarding-sheet__text-alert-title"> Se contabilizó las siguientes fechas
          </span>
          <br/>
          ${completed.map(({date, headquarter}) => `${date} - ${headquarter}`).join("<br/>")}
          <br/>
          <br/>
          <span class="class="boarding-sheet__text-alert-title">No se pudo contabilizar las siguientes fechas
          </span>
          <br/>
          ${failure.map(({date, headquarter}) => `${date} - ${headquarter}`).join("<br/>")}
          `,
        })
    }
      
    } catch (err) {
      if (err.code === "TRVL057") {
        setState((prevState) => ({
          ...prevState,
          isAlertModal: true
        }))
      }
      if (err.code === "TRVL020") {
        setState((prevState) => ({
          ...prevState,
          isAlertModal: true
        }))
      }

      setState((prevState) => ({
        ...prevState,
        isLoadingSpin: false,
        isLoadingModalSpin: false
      }))

      ErrorToastComponent({
        title: BoardingSheetErrorsConst.passages[err.code] || BoardingSheetErrorsConst.default,
        position: AlertConst.TOP_END_POSITION_TEXT,
        timer: 2500
      })
    }
  }  

  const handleSearchReset = async () => {
    await Promise.allSettled([
      getAllManifestTravelService("", 0),
      getAllManifestTravelCountService("")
    ])
    .then((res)=>{
      let boardingSheetList = [];
      let countPage = 0

      for (let index = 0; index < res.length; index++) {
        const elementResponse = res[index];

        if (elementResponse.status === AppConst.FULFILLED) {
          switch (index) {
            case 0:
              boardingSheetList = elementResponse?.value
              break;
            case 1:
              countPage = elementResponse?.value
              break;
            default:
              break;
          }
        }
      }

      setState((prevState) => ({
        ...prevState,
        listBoardingSheet: boardingSheetList,
        countPage: countPage,
        pageSelected: 1,
        textFilter: ""
      }))
    })
    .catch(() => {
      // TODO: Implement error alert with code error.
    })
  }

  const handlePageChange = async(page) => {
    try {
      const boardingSheetList = await getAllManifestTravelService(textFilter, page - 1);
      setState({
        ...state,
        listBoardingSheet: boardingSheetList,
        pageSelected: page
      });
    } catch (error) {
      // TODO: Implement error alert with code error.
    }
  };

  const printModalTicket = async (size) => {

    let dataPreviewResponse = await getDetailsTemporaryPayrollService(dataPreview?.travel?.id);
    const { connectSocket, printTicketSocket } = PrintConnectionSocket();
    connectSocket();
    printTicketSocket({ typeTicket: 'printPassagesForm', data: dataPreviewResponse });
  }

  const viewDetails = (item) => {
    setState({
      ...state,
      isViewDetails: true,
      itemPreview: item
    })
  }

  const partialAssignmentsModal = (item) => {
    setState({
      ...state,
      isPartialAssignmentsModal: true,
      itemSelected: item
    })
  }

  const modalTransferPassages = (item) => {
    setState({
      ...state,
      isPartialAssignmentsModal: true,
      itemSelected: item,
      isTransferPassages: true
    })
  }

  const downloadExcel = async(item) => {
    try {
      const dataExcel = await getManifestByIdService(item?.travel?.id)

      const dataTickets = dataExcel?.travel?.invoices?.tickets?.data?.map((ticket) => {
        return [
          `${ticket?.prefix}${ticket?.number}`,
          ticket?.name,
          `${ticket?.passengerName} ${ticket?.passengerLastName}`,
          dataExcel?.travel?.route?.municipalityArrive,
          ticket?.price
        ]
      })

      const dataOrders = dataExcel?.travel?.invoices?.shippings?.data?.map((order) => {
        return [
          `${order?.prefix}${order?.number}`,
          `${order?.clientReceives?.name} ${order?.clientReceives?.lastName}`,
          dataExcel?.travel?.route?.municipalityArrive,
          order?.price
        ]
      })

      boardingSheetExcelUtils({
        summaryData:{
          extraContributions: dataExcel?.travel.othersDetails.extraContributions,
          socialBenefits: dataExcel?.travel.othersDetails.socialBenefits,
          loanPayment: dataExcel?.travel.othersDetails.loanPayment,
          manifest: dataExcel?.travel.othersDetails.manifest,
          contributions: dataExcel?.travel.othersDetails.contributions,
          replacement: dataExcel?.travel.othersDetails.replacement,
          additionalInsurance: dataExcel?.travel.othersDetails.additionalInsurance,
          subTotal: dataExcel?.travel?.subTotal,
          discount: dataExcel?.travel.discount,
          totalManifestPrice: dataExcel?.travel.totalManifestPrice
        },
        headerValues: [
          dataExcel?.vehicle?.internalNumber,
          `${dataExcel?.driver?.name} ${dataExcel?.driver?.lastName}`,
          dataExcel?.travel?.route?.municipalityDepart,
          dataExcel?.travel?.route?.municipalityArrive,
          dataExcel?.vehicle?.plate,
          `${dataExcel?.vehicle?.owner?.name} ${dataExcel?.vehicle?.owner?.lastName}`,
          dayjs(dataExcel?.travel?.time, 'HH:mm:ss').locale('es').format('h:mm a'),
          dayjs(dataExcel?.travel?.date).format('DD/MM/YYYY')
        ],
        fileTitle: item?.travel?.manifestNumber,
        dataTickets: dataTickets,
        dataOrders: dataOrders
      })
      
    } catch {
      // TODO: Implement error alert with code error.
    }
  }
  const modalAccept = async() => {
    if (isGenerate) {
      try {
        const shipmentIds = selectedShipments.length > 0 ? selectedShipments.map(item => item.id) : null;
        
        if (shipmentIds){
          await getOrderWithGuideService(shipmentIds, itemSelected?.travel?.id);
        }

        await createManifestNumberService(
          itemSelected?.travel?.id, 
          {
            idDriverVehicle: driverSelected?.idDriverVehicle || itemSelected?.driverVehicle?.id,
            date: dayjs(dateSelected).format('YYYY-MM-DD'),
            loanPayment: itemSelected?.travel?.loanPayment,
            extraContributions: itemSelected?.travel?.extraContributions,
            contributions: itemSelected?.travel?.contributions,
            socialSecurity: itemSelected?.travel?.socialSecurity,
            insuranceFund: itemSelected?.travel?.insuranceFund,
            otherIncome: itemSelected?.travel?.otherIncome,
            cucutaContributions: itemSelected?.travel?.cucutaContributions
          },
        )
        const listGenerator = await getManifestTravelByDateService(dayjs(weekSelected).format('YYYY-MM-DD'))
        setState((prevState) => ({
          ...prevState,
          listGenerator: listGenerator,
        }))
        handleSearch()
        modalClose()
      } catch (err) {
        ErrorToastComponent({
          title: BoardingSheetErrorsConst.passages[err.code] || BoardingSheetErrorsConst.default,
          position: AlertConst.TOP_END_POSITION_TEXT,
          timer: 1500
        })
      }
    }else{
      try {
        const data = { 
          idTravel: vehicleSelected?.travel?.id 
        }
        if (observations) {
          data.observation = observations
        }
        await updateAssignTravelAnotherVehicleService(itemSelected?.travel?.id,data)
        const listGenerator = await getManifestTravelByDateService(dayjs(weekSelected).format('YYYY-MM-DD'))
        setState((prevState) => ({
          ...prevState,
          listGenerator: listGenerator,
        }))
        modalClose()
      } catch {
        // TODO: Implement error alert with code error.
      }
    }
  }

  const modalClose = () => {
    setState((prevState) => ({
      ...prevState,
      isOpenModal: false,
      isGenerate: false,
      itemSelected: null,
      vehicleSelected: null,
      driverSelected: null,
      isPartialAssignmentsModal: false,
      selectedShipments: [],
      isTemporaryPayroll: false,
      itemPreview: null,
      isTransferPassages: false
    }))
  }

  const printTicket = async(item) => {
    try {
      const dataPreview = await getManifestByIdService(item?.travel?.id);
      const blob = await pdf(
        <PdfDetailsComponent dataPreview={[dataPreview]} />
      ).toBlob();
  
      const url = URL.createObjectURL(blob);
      window.open(url)
      URL.revokeObjectURL(url);
    } catch {
      // TODO: Implement error alert with code error.
    }
  }

  const modalAcceptPreview = async () => {
    try {
      if (isTransferPassages) {
        await patchPassagesListingService(optionsVehicleSearch?.travel?.id, optionsShipping.map(item => item.id))
      }else{
        await getOrderWithGuideService(optionsShipping.map(item => item.id), optionsVehicleSearch?.travel?.id);
      }

      const listGenerator = await getManifestTravelByDateService(dayjs(weekSelected).format('YYYY-MM-DD'))
      setState((prevState) => ({
        ...prevState,
        listGenerator: listGenerator,
      }))

      modalClose()

    } catch (error) {
      ErrorToastComponent()
    }
  }

  const downloadAllPdf = async() => {
    try {
      
      const listBoardingSheetArray = (listBoardingSheet.map(item => item?.travel?.id))
      const dataAllPdf = await getDataAllPdfService(listBoardingSheetArray)
      const blob = await pdf(<PdfDetailsComponent dataPreview={dataAllPdf} />).toBlob();
  
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `document${Date.now()}.pdf`;
      link.click();
  
      URL.revokeObjectURL(url);
    } catch {
      // TODO: Implement error alert with code error.
    }
  }

  const handleTemporaryPayroll = async(item) => {
    try {
      const resp = await getTemporaryPayroll(item?.travel?.id)
      const respDataPreview = await getDetailsTemporaryPayrollService(item?.travel?.id)
      setState({
        ...state,
        isTemporaryPayroll: true,
        itemSelected: resp,
        dataPreview: respDataPreview
      })
      
    } catch (err) {
      ErrorToastComponent({
        title: BoardingSheetErrorsConst.passages[err.code] || BoardingSheetErrorsConst.default,
        position: AlertConst.TOP_END_POSITION_TEXT,
        timer: 1500
      })
    }
  }

  const getTemporaryPayroll = async(id) => {
    try {
      return await getTemporaryPayrollService(id)
    } catch (err) {
      ErrorToastComponent({
        title: BoardingSheetErrorsConst.passages[err.code] || BoardingSheetErrorsConst.default,
        position: AlertConst.TOP_END_POSITION_TEXT,
        timer: 1500
      })
    }
  }

  const modalTemporarySave = async() => {
    try {
      await updateTemporaryPayrollService(itemSelected?.id, {
        idDriverVehicleTemp: driverSelected?.idDriverVehicle || itemSelected?.idDriverVehicleTemp, 
        loanPayment: itemSelected.loanPayment, 
        otherIncome: itemSelected.otherIncome, 
        cucutaContributions: itemSelected.cucutaContributions,
        extraContributions: itemSelected.extraContributions, 
        contributions: itemSelected.contributions, 
        socialSecurity: itemSelected.socialSecurity, 
        insuranceFund: itemSelected.insuranceFund
      })
      SuccessToastComponent({
        html:
            `<span>
              Guardado con éxito
            </span>`,
        position: AlertConst.TOP_END_POSITION_TEXT,
        timer: 1500
      })

    } catch (err) {
      ErrorToastComponent({
        title: BoardingSheetErrorsConst.passages[err.code] || BoardingSheetErrorsConst.default,
        position: AlertConst.TOP_END_POSITION_TEXT,
        timer: 1500
      })
    }
  }

  const printPdf = async (url) => {
    window.open(url)
  };

  const generateExcel = async() => {
    try {
      
      const dataExcel = await getDetailsTemporaryPayrollService(itemSelected?.id)
      const dataTickets = dataExcel?.travel?.invoices?.tickets?.data?.map((ticket) => {
        return [
          `${ticket?.prefix}${ticket?.number}`,
          ticket?.name,
          `${ticket?.passengerName} ${ticket?.passengerLastName}`,
          dataExcel?.travel?.route?.municipalityArrive,
          ticket?.price
        ]
      })

      const dataOrders = dataExcel?.travel?.invoices?.shippings?.data?.map((order) => {
        return [
          `${order?.prefix}${order?.number}`,
          `${order?.clientReceives?.name} ${order?.clientReceives?.lastName}`,
          dataExcel?.travel?.route?.municipalityArrive,
          order?.price
        ]
      })

      boardingSheetExcelUtils({
        summaryData:{
          extraContributions: dataExcel?.travel.othersDetails.extraContributions,
          socialBenefits: dataExcel?.travel.othersDetails.socialBenefits,
          loanPayment: dataExcel?.travel.othersDetails.loanPayment,
          manifest: dataExcel?.travel.othersDetails.manifest,
          contributions: dataExcel?.travel.othersDetails.contributions,
          replacement: dataExcel?.travel.othersDetails.replacement,
          additionalInsurance: dataExcel?.travel.othersDetails.additionalInsurance,
          subTotal: dataExcel?.travel?.subTotal,
          discount: dataExcel?.travel.discount,
          totalManifestPrice: dataExcel?.travel.totalManifestPrice
        },
        headerValues: [
          dataExcel?.vehicle?.internalNumber,
          `${dataExcel?.driver?.name} ${dataExcel?.driver?.lastName}`,
          dataExcel?.travel?.route?.municipalityDepart,
          dataExcel?.travel?.route?.municipalityArrive,
          dataExcel?.vehicle?.plate,
          `${dataExcel?.vehicle?.owner?.name} ${dataExcel?.vehicle?.owner?.lastName}`,
          dayjs(dataExcel?.travel?.time, 'HH:mm:ss').locale('es').format('h:mm a'),
          dayjs(dataExcel?.travel?.date).format('DD/MM/YYYY')
        ],
        fileTitle: itemSelected?.manifestNumberTemporal,
        dataTickets: dataTickets,
        dataOrders: dataOrders
      })
      
    } catch (err) {
      ErrorToastComponent({
        title: BoardingSheetErrorsConst.passages[err.code] || BoardingSheetErrorsConst.default,
        position: AlertConst.TOP_END_POSITION_TEXT,
        timer: 1500
      })
    }
  }


  const handleDelete = async (item) => {
    try {
      await deletePayrollService(item?.travel?.id)
      handleSearch()
      SuccessToastComponent({
        html:
        `<span>
        Eliminado con éxito
        </span>`,
        position: AlertConst.TOP_END_POSITION_TEXT,
        timer: 1500
      })
    } catch (err) {
      ErrorToastComponent({
        title: BoardingSheetErrorsConst.delete[err.code] || BoardingSheetErrorsConst.default,
        position: AlertConst.TOP_END_POSITION_TEXT,
        timer: 1500
      })
    }
  }

  return (
    <>
      <div className='boarding-sheet__breadcrumbs'>
        <span>Planilla</span>
      </div>
      <div className='boarding-sheet__container'>
        <div className='grid-x justify-content-between boarding-sheet__container__header'>
            <span className='boarding-sheet__title'>Generador de planilla</span>
            <div className='grid-x justify-content-between boarding-sheet__select-week'>
              <button>
                <img
                  src={Assets.SalesIcons.icon_back}
                  alt='icon_back'
                  onClick={() => previousWeek()}
                />
              </button>
                <DatePicker
                  defaultValue={dayjs()}
                  format={(value)=>{
                    return `${dayjs(value)?.format("DD/MM/YYYY")}`;
                  }
                  }
                  picker="date"
                  onChange={(date)=>handleWeek(date)}
                  value={weekSelected}
                  allowClear={false}
                  variant="borderless"
                  suffixIcon={null}
                  placeholder=""
                  className='boarding-sheet__select-week__date'
                  superNextIcon={
                    <img src={Assets.SalesIcons.icon_forward} alt="icon_forward" />
                  }
                  superPrevIcon={
                    <img src={Assets.SalesIcons.icon_back} alt="icon_back" />
                  }
                  nextIcon={
                    <img src={Assets.SharedIcons.icon_next} alt="icon_next" />
                  }
                  prevIcon={
                    <img src={Assets.SharedIcons.icon_back} alt="icon_back" />
                  }
                  popupClassName="boarding-sheet__select-week__date__picker"
                  
                />
              <button>
                <img
                  src={Assets.SalesIcons.icon_forward}
                  alt='icon_forward'
                  onClick={()=> nextWeek()}
                />
              </button>
            </div>
        </div>
        <div className='boarding-sheet__box boarding-sheet__box--mod grid-x'>
          <div className='boarding-sheet__subtitle small-2'>
            <span>Hora de Salida</span>
          </div>
          <div className='grid-x small-10'>
            <span className='boarding-sheet__subtitle small-2'>Código del vehículo</span>
            <span className='boarding-sheet__subtitle small-2'>Nro. del vehículo</span>
            <span className='boarding-sheet__subtitle small-1'>Nombre del conductor</span>
            <span className='boarding-sheet__subtitle small-1'>Pasajes vendidos</span>
            <span className='boarding-sheet__subtitle small-2'>Encomiendas</span>
            <span className='boarding-sheet__subtitle small-4'></span>
          </div>
          <div className='small-12 boarding-sheet__generate'>
            {listGenerator?.map((table, index) => (
              <div className='grid-x' key={index}>
                <div className='small-2 boarding-sheet__subtitle__hour'>
                  {table?.id}
                </div>
                <div className='small-10 grid-x'>
                  {table?.items?.map((item, indexTable) => (
                    <div className='small-12 grid-x' key={indexTable}>
                      <div className='boarding-sheet__separator'></div>
                      <span className='small-2 text-center boarding-sheet__text'>{item?.vehicle?.plate}</span>
                      <span className='small-2 text-center boarding-sheet__text'>{item?.vehicle?.internalNumber}</span>
                      <span className='small-1 text-center boarding-sheet__text'>{item?.driver?.name}</span>
                      <span className='small-1 text-center boarding-sheet__text'>{`${item?.travel?.ticketsSales}/${item?.travel?.totalSeat}`}</span>
                      <span className='small-2 text-center boarding-sheet__text'>{item?.travel?.totalShipping}</span>
                      <div className='small-4 grid-x justify-content-between'>
                        { headquarterAssociated === BoardingSheetConst.CUCUTA  &&
                          <button
                            className="small-2 grid-x align-center-middle boarding-sheet__button--warning"
                            onClick={() => handleTemporaryPayroll(item)}
                          >
                            <img
                              src={Assets.SharedIcons.icon_generate}
                              alt="icon_generate"
                            />
                            <span className='small-6 grid-x boarding-sheet__button__text'>
                              Planilla temporal
                            </span>
                          </button>
                        }
                        <button
                          className={`${headquarterAssociated === BoardingSheetConst.CUCUTA ? "small-2" : "small-2"} grid-x align-center-middle boarding-sheet__button boarding-sheet__button--mod`}
                          onClick={() =>
                            setState({
                              ...state,
                              isOpenModal: true,
                              isGenerate: true,
                              itemSelected: item
                            })
                          }
                        >
                          <img
                            src={Assets.SharedIcons.icon_generate}
                            alt="icon_generate"
                          />
                          <span className='small-6 grid-x boarding-sheet__button__text'>
                            Generar planilla
                          </span>
                        </button>
                        <button
                          className="grid-x boarding-sheet__button__width align-center-middle boarding-sheet__button__transfer boarding-sheet__button--mod"
                          onClick={() => setState({
                            ...state,
                            isOpenModal: true,
                            isGenerate: false,
                            itemSelected: item
                          })}
                        >
                          <img
                            src={Assets.SharedIcons.icon_transfer}
                            alt="icon_transfer"
                          />
                          <span className='small-6 grid-x boarding-sheet__button__text'>
                            Transferir planilla
                          </span>
                        </button>
                        <button
                          className="boarding-sheet__button__width grid-x align-center-middle boarding-sheet__button__transfer boarding-sheet__button--mod"
                          onClick={() => partialAssignmentsModal(item)}
                        >
                          <img
                            height={11}
                            width={11}
                            src={Assets.SharedIcons.icon_hour}
                            alt='icon_view_details'
                          />
                          <span className='small-7 grid-x boarding-sheet__button--hour'>
                            Transferir encom...
                          </span>
                        </button>
                        <button
                          className="boarding-sheet__button__width grid-x align-center-middle boarding-sheet__button__transfer boarding-sheet__button--mod"
                          onClick={() => modalTransferPassages(item)}
                        >
                          <img
                            height={11}
                            width={11}
                            src={Assets.SharedIcons.icon_hour}
                            alt='icon_view_details'
                          />
                          <span className='small-7 grid-x boarding-sheet__button--hour'>
                            Transferir Pasajes
                          </span>
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
            {listGenerator?.length === 0 &&
              <div className='grid-y align-center-middle' style={{ height: "100%" }}>
                <img
                  src={Assets.SalesIcons.icon_broken_link}
                  alt="icon_broken_link"
                />
                <h4>Lo sentimos, aún no tienes planillas disponibles</h4>
              </div>
            }
          </div>
        </div>
      </div>
      <div className='boarding-sheet__container boarding-sheet__container--mod'>
        <div className='grid-x boarding-sheet__container__header '>
          <div className='grid-y small-4' style={{width: "25%"}}>
            <span className="boarding-sheet__info">Buscar</span>
            <Input
                allowClear={{
                  clearIcon:(
                    <button
                      onClick={() => {
                        handleSearchReset()
                      }}
                    >
                      <img
                        width={28}
                        src={Assets.SharedIcons.icon_square_close}
                        alt="icon_logo_vertical"
                      />
                    </button>
                  )
                }}
                className="boarding-sheet__input__content "
                placeholder="Buscar planilla por código, placa o fecha"
                prefix={
                    <img
                    className="boarding-sheet__input__search"
                    src={Assets.SharedIcons.icon_search}
                    alt="icon_search"
                    />
                }
                onChange={(e) => {
                  setState({
                    ...state,
                    textFilter: e?.target?.value?.trim()
                  })
                }}
            />
          </div>
          <div className='grid-x small-3 justify-content-center align-bottom'>
          <DatePicker.RangePicker
                  format={"YYYY-MM-DD"}
                  value={initialDateRange && endDateRange ? [dayjs(initialDateRange, 'YYYY-MM-DD'), dayjs(endDateRange, 'YYYY-MM-DD')] : null}
                  onChange={(e, date) => onChange(date, 'rangePicker')}
                  className="boarding-sheet__range-picker"
                  separator={<img src={Assets.SalesIcons.icon_right_arrow} alt="icon_right_arrow" />}
                  suffixIcon={<img src={Assets.SalesIcons.icon_calendar} alt="icon_calendar"/>}
                  allowClear={{
                    clearIcon:(<img src={Assets.SalesIcons.icon_clear_calendar} alt="icon_clear_calendar"/>)
                  }}
                  popupClassName="boarding-sheet__range-picker__modal"
                  superNextIcon={
                    <img src={Assets.SalesIcons.icon_forward} alt="icon_forward" />
                  }
                  superPrevIcon={
                    <img src={Assets.SalesIcons.icon_back} alt="icon_back" />
                  }
                  nextIcon={
                    <img src={Assets.SharedIcons.icon_next} alt="icon_next" />
                  }
                  prevIcon={
                    <img src={Assets.SharedIcons.icon_back} alt="icon_back" />
                  }
            />
            
          </div>
           
          <div className='grid-x small-2 justify-content-center align-bottom'>
            <button
              className="boarding-sheet__button"
              
              onClick={()=> handleSearch()}
            >
              Buscar
            </button>
          </div>
          {(showButton && isPayrollAdministrator) &&  (
            <div className='grid-x small-2 justify-content-left align-bottom'>
              <button
                disabled={isLoadingSpin}
                className="boarding-sheet__button__transfer align-center-middle"
                onClick={() => {
                  setState((prevState) => ({...prevState, isLoadingSpin: true}));
                  handleAccounting()
                }}
              >
                {isLoadingSpin
                  ? <Spin size='default' className='boarding-sheet__button__transfer__spin' />
                  : 'Contabilizar'
                }
              </button>
            </div>
          )}
          <div className='grid-x small-2 justify-content-end align-bottom'>
            <button 
              disabled={!endDateRange && !initialDateRange && !textFilter}
              className='boarding-sheet__button__transfer boarding-sheet__button--download-all-pdf'
              onClick={()=> downloadAllPdf()}
            >
              <span className = "boarding-sheet__button__text">Descargar planillas</span>
              <img 
                className='boarding-sheet__button__icon-download'  
                src={!endDateRange && !initialDateRange && !textFilter ? Assets.SharedIcons.icon_download_inactive : Assets.SharedIcons.icon_download_color_citron}
                alt="icon_download_color_citron" 
              />
            </button>
          </div>

        </div>
        <div className='boarding-sheet__box grid-x' style={{minHeight: "290px", alignContent: "flex-start"}}>
          {
            headerTableFilter.map((item, index) => {
              return (
                <div key={index} className={`grid-x boarding-sheet__subtitle__content ${index === 1 || index === 4 ? "small-1" : "small-2"}`}>
                  <span className='boarding-sheet__subtitle'>{item}</span>
                </div>
              )
            })
          }
          <hr className='small-12 boarding-sheet__line'/>
          { listBoardingSheet?.length > 0 ?
            listBoardingSheet?.map((item, index) => {
              return (
                <div key={index} className='grid-x small-12' style={{height: "40px"}}>
                  <div className="grid-x boarding-sheet__subtitle__content__text small-2">
                    <span className="boarding-sheet__text">{item?.travel?.manifestNumber}</span>
                  </div>
                  <div className="grid-x boarding-sheet__subtitle__content__text small-1">
                    <span className="boarding-sheet__text">{item?.vehicle?.plate}</span>
                  </div>
                  <div className="grid-x boarding-sheet__subtitle__content__text small-2">
                    <span className="boarding-sheet__text">{item?.vehicle?.internalNumber}</span>
                  </div>
                  <div className="grid-x boarding-sheet__subtitle__content__text small-2">
                    <span className="boarding-sheet__text">{ `${item?.driver?.name} ${item?.driver?.lastName}`}</span>
                  </div>
                  <div className="grid-x boarding-sheet__subtitle__content__text small-1">
                    <span className="boarding-sheet__text">{item?.route?.name}</span>
                  </div>
                  <div className="grid-x boarding-sheet__subtitle__content__text small-2">
                    <span className="boarding-sheet__text">{item?.travel?.date}</span>
                  </div>
                  <div className="grid-x justify-content-evenly boarding-sheet__subtitle__content__text small-2">
                    <span className="boarding-sheet__text boarding-sheet__countability">{item?.travel?.isSynchronized ? "C" : ""}</span>
                    <button 
                      className='boarding-sheet__content-actions-button'
                      onClick={()=> viewDetails(item)}
                    >
                      <img
                        src={Assets.SharedIcons.icon_view_details}
                        alt='icon_view_details'
                      />
                    </button>
                    <button 
                      className='boarding-sheet__content-actions-button'
                      onClick={()=> printTicket(item)}
                      >
                      <img
                        src={Assets.SharedIcons.icon_print_ticket}
                        alt='icon_print_ticket'
                      />
                    </button>
                    <button
                      onClick={() => 
                        downloadExcel(item)
                      }
                      className='boarding-sheet__content-actions-button'
                    >
                      <img
                        src={Assets.SharedIcons.icon_download}
                        alt='icon_download'
                      />
                    </button>
                    { isPayrollAdministrator &&
                      <button
                        onClick={() => 
                          handleDelete(item)
                        }
                        className='boarding-sheet__content-actions-button'
                      >
                        <img
                          src={Assets.SharedIcons.icon_wastebasket}
                          alt='icon_wastebasket'
                        />
                      </button>
                    }
                  </div>
                  {  listBoardingSheet?.length -1 !== index &&
                    <hr className='small-12 boarding-sheet__line'/>
                  }
                </div>
              )
            })
            :
            <div className='grid-y align-center-middle' style={{height: "240px", width: "100%"}}>
              <img
                src={Assets.SalesIcons.icon_broken_link}
                alt="icon_broken_link"
              />
              <h4>Lo sentimos, aún no tienes planillas disponibles</h4>
            </div>
          }
        </div>
      </div>
      <div className="grid-x justify-content-between boarding-sheet__container">
          <span className="boarding-sheet__info">
            {`${Math.min(pageSelected * 5, countPage)}  ${t("sales.salesFooter.salesTable.from")} ${countPage} ${t("sales.salesFooter.salesTable.results")}`}
          </span>
          <div className="grid-x boarding-sheet__pagination__content">
            <Pagination
              page={pageSelected}
              disabled={countPage === 0  ? true : false}
              onChange={(e, event) => handlePageChange(event)}
              count={Math.ceil(countPage / 5) || 1}
              siblingCount={0}
              boundaryCount={0}
              showFirstButton
              showLastButton
              shape="rounded"
              className="boarding-sheet__pagination"
              renderItem={(item) => (
                <PaginationItem
                  className="boarding-sheet__pagination__item"
                  slots={{
                    previous: () => {
                      return (
                        <img
                          src={Assets.SharedIcons.icon_back}
                          alt="icon_back"
                        />
                      )
                    },
                    next: () => {
                      return (
                        <img
                          src={Assets.SharedIcons.icon_next}
                          alt="icon_forward"
                        />
                      )
                    },
                    first: () => {
                      return (
                        <img
                          src={Assets.SalesIcons.icon_back}
                          alt="icon_back"
                        />
                      )
                    },
                    last: () => {
                      return (
                        <img
                          src={Assets.SalesIcons.icon_forward}
                          alt="icon_back"
                        />
                      )
                    },
                  }}
                  {...item}
                />
              )}
            />
          </div>
        </div>
        { isOpenModal &&
          <ModalMainComponent
            close
            width={380}
            open={isOpenModal}
            onClose={()=> modalClose()}
            titleIcon={<img src={Assets.SharedIcons.icon_logo_and_name} alt='icon_logo_and_name'/>}
            footer={[
              <div key="accept" className='grid-x align-center'>
                <button 
                  disabled={(isGenerate && !driverSelected) || (!isGenerate && !vehicleSelected)}
                  onClick={()=> modalAccept()}
                  className='boarding-sheet__modal__button'
                >
                  <span>{isGenerate ? "Generar" : "Transferir planilla"}</span>
                </button>
              </div>
            ]}
          >
            {
              isGenerate ?
                <ModalGenerateComponent 
                  daySelected={dateSelected}
                  itemSelected={itemSelected} 
                  driverSelected={driverSelected}
                  selectedShipments={selectedShipments}
                  setSelectedShipments={(select) => setState ({...state, selectedShipments: select})}
                  setDriverSelected={(driver) => setState({...state, driverSelected: driver})}
                  setDaySelected={(day) => setState({...state, dateSelected: day})}
                  setDataGeneratePayroll={(data) =>{
                    setState({
                      ...state,
                      itemSelected: {
                        ...itemSelected,
                        travel: data
                      }
                    })
                  }}
                />
              :
                <ModalTransferComponent
                  observation={(text) => setState({...state, observations: text})}
                  daySelected={weekSelected}
                  itemSelected={itemSelected}
                  setVehicleSelected={(vehicle) => setState({...state, vehicleSelected: vehicle})}
                />
            }
          </ModalMainComponent>
        }
        { isViewDetails &&
          <ModalDetailsComponent
            open={isViewDetails}
            onClose={()=> setState({...state, isViewDetails: false})}
            itemPreview={itemPreview}
            isTemporaryPayroll={isTemporaryPayroll}
          />
        }

        { isPartialAssignmentsModal &&
          <ModalMainComponent
            close
            width={380}
            open={isPartialAssignmentsModal}
            onClose={()=> modalClose()}
            titleIcon={<img src={Assets.SharedIcons.icon_logo_and_name} alt='icon_logo_and_name'/>}
            footer={[
              <div key="accept" className='grid-x align-center'>
                <button
                  className='boarding-sheet__modal__button'
                  onClick={()=> modalAcceptPreview()}
                  disabled= {isDisabledModalPreview}
                >
                  <span>{"Aceptar"}</span>
                </button>
              </div>
            ]}
          >
            <ModalPreviewComponent
             isTransferPassages={isTransferPassages}
             itemSelected={itemSelected} 
             daySelected={weekSelected}
             setVehicleSelected={(vehicle) => setState({...state, vehicleAvailable: vehicle})}
             setDisabled={(isDisabled) => setState({...state, isDisabledModalPreview: isDisabled})} 
            /> 
          </ModalMainComponent> 
        }
      {isTemporaryPayroll &&
        <ModalMainComponent
          close
          width={400}
          open={isTemporaryPayroll}
          onClose={() => modalClose()}
          titleIcon={<img src={Assets.SharedIcons.icon_logo_and_name} alt='icon_logo_and_name' />}
          footer={[
            <div key="accept" className='grid-x justify-content-between'>
              <button
                title='Imprimir ticket'
                onClick={() => printModalTicket()}
                className='boarding-sheet__modal__buttons'
              >
                <img
                  src={Assets.SharedIcons.icon_format}
                  alt="icon_format"
                />
              </button>
              <button
                title='Ver detalles'
                onClick={() =>
                  viewDetails({
                    travel: {
                      id: itemSelected?.id,
                      manifestNumber: itemSelected?.manifestNumberTemporal
                    }
                  })
                }
                className='boarding-sheet__modal__buttons'
              >
                <img
                  src={Assets.SharedIcons.icon_view_details}
                  alt="icon_view_details"
                />
              </button>
              <BlobProvider document={<PdfDetailsComponent dataPreview={[dataPreview]} />}>
                {({ url, loading, error }) => {
                  return (
                    <button
                      title='Imprimir planilla'
                      className='boarding-sheet__modal__buttons'
                      onClick={() => printPdf(url)}
                      disabled={loading || error}
                    >
                      <img
                        src={Assets.SalesIcons.icon_print_ticket_active}
                        alt="icon_print"
                        width={20}
                        height={20}
                      />
                    </button>
                  );
                }}
              </BlobProvider>
              <button
                title='Descargar planilla'
                onClick={() => generateExcel()}
                className='boarding-sheet__modal__buttons'
              >
                <img
                  src={Assets.SharedIcons.icon_download}
                  alt="icon_download"
                  width={20}
                  height={20}
                />
              </button>
            </div>,
            <button
              key="cancel"
              className='boarding-sheet__modal__button boarding-sheet__modal__button__mod'
              onClick={()=> modalTemporarySave()}
            >
              <span>{"Guardar"}</span>
            </button>
          ]}
        >
          <ModalTemporaryPayrollComponent
            itemSelected={itemSelected}
            driverSelected={driverSelected}
            selectedShipments={selectedShipments}
            selectedPassages={selectedPassages}
            setStateModal={(stateModal) => setState((prevState) => ({ ...prevState, ...stateModal }))}
            setDataGeneratePayroll={(data) => {
              setState({
                ...state,
                itemSelected: {
                  ...itemSelected,
                  ...data
                }
              })
            }}
          />
        </ModalMainComponent>
      } 
      {isAlertModal &&
        <ModalMainComponent
          open={isAlertModal}
          onClose={() => setState({ ...state, isAlertModal: false, isLoadingModalSpin: false })}
          width={300}
          close
          footer={[
            <button
              key="accept"
              disabled={isLoadingModalSpin}
              className="sales-table__modal-electronic__button"
              onClick={()=>{
                setState((prevState) => ({...prevState, isLoadingModalSpin: true}));
                handleAccounting(true)
              }}
            >
              <span className="sales-table__modal-electronic__button__label">
                {isLoadingModalSpin
                  ? <Spin size='default' className='boarding-sheet__spin' />
                  : 'Aceptar'
                }
              </span>
            </button>,
            <div className="sales-table__modal-electronic__close" key="cancel">
              <button
                className="sales-table__modal-electronic__close__label"
                onClick={() => setState({ ...state, isAlertModal: false, isLoadingModalSpin: false })}
              >
                Cancelar
              </button>
            </div>
          ]}
          titleIcon={
            <div className="grid-x align-center-middle">
              <img src={Assets.SharedIcons.icon_logo_and_name} alt='icon_logo_and_name' />
            </div>
          }
        >
          <span className='grid-x text-center boarding-sheet__title'>
            Hay planillas que ya fueron contabilizadas, deseas continuar de todos modos
          </span>
        </ModalMainComponent>
      }     
    </>
  )
}

const mapStateToProps = ({ BoardingSheetReducer }) => {
  const { optionsShipping,optionsVehicleSearch } = BoardingSheetReducer;
  return {
      optionsShipping,
      optionsVehicleSearch
  };
};

export default connect(mapStateToProps, null)(BoardingSheetPage);
