import dayjs from "dayjs";

export const createFilterObject = (valueFilterInput = "", selectSale = {}, initialDateRange = "", endDateRange = "") => {
    let objectFilter = {
        page: 1
    }

    valueFilterInput?.trim() !== "" && (objectFilter.filterValue = valueFilterInput?.trim())

    switch (selectSale?.value) {
        case 0:
            objectFilter.filterType = "dateRange"
            objectFilter.startDate = dayjs(`${initialDateRange}`, 'DD/MM/YYYY').format('YYYY-MM-DD')
            objectFilter.endDate = dayjs(`${endDateRange}`, 'DD/MM/YYYY').format('YYYY-MM-DD');
            break;

        case 1:
            valueFilterInput?.trim() !== "" && (objectFilter.filterType = "price")
            break;
        case 2:
            valueFilterInput?.trim() !== "" && (objectFilter.filterType = "sellerName")
            break;
        case 3:
            valueFilterInput?.trim() !== "" && (objectFilter.filterType = "municipalityArrive")
            break;
        case 4:
            valueFilterInput?.trim() !== "" && (objectFilter.filterType = "clientNumberDocument")
            break;
        case 5:
            valueFilterInput?.trim() !== "" && (objectFilter.filterType = "clientName")
            break;
        case 6:
        valueFilterInput?.trim() !== "" && (objectFilter.filterType = "methodPayment")
        break;

        default:
            break;
    }

    return objectFilter
}
