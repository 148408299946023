//Actions
import {
  setDataSaleReducer,
  setDataSalesStateReducer,
  setStateSaleReducer,
} from "../../../../../storage/reducers/sales/sales.action";

//Assets
import { Assets } from "../../../../../assets";

//Constants
import AppConst from "../../../../../core/constants/app.const";
import SalesConst from "../../../../../core/constants/sales.const";

//Libraries
import { AutoComplete, Input, Select } from "antd";
import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import dayjs from "dayjs";
import Checkbox from "antd/es/checkbox/Checkbox";

//Services
import { getAllShippingTypeService, getServiceTypeService } from "../../../../../services/shippings.services";
import { getAllPaymentMethodService } from "../../../../../services/shared.services";
import { getAllVehiclesAvailableTravelService, getConstantSystemService } from "../../../../../services/sales.services";

//Styles
import "./ordering-data.component.scss"

//Utils
import { shippingsOrderDataSchema } from "../../../../../utils/form-validations/sales.validators.utils";

const OrderingDataComponent = (props) => {
  const {
    setDataSalesStateReducer,
    setStateSaleReducer,
    setDataSaleReducer,
    isDisabled,
    nextButtonRef,
    ...orderingData
  } = props;
  const [t] = useTranslation("translation");

  const INITIAL_STATE = {
    optionsShippingType: [],
    optionsPaymentMethod: [],
    optionsServiceType: [],
    optionsVehicles: [],
    shippingOthers: undefined,
    SelectedMessage: "",
    isDropdownOpen: false
  }

  const [state, setState] = useState(INITIAL_STATE)

  const { 
    optionsShippingType,
    optionsPaymentMethod,
    optionsServiceType,
    optionsVehicles,
    shippingOthers,
    SelectedMessage,
    isDropdownOpen
  } = state

  const serviceTypeRef = useRef();
  const weightAmountRef = useRef();
  const typePackageRef = useRef();
  const contentPackageRef = useRef();
  const declaredValueRef = useRef();
  const costOrderRef = useRef();
  const quantityRef = useRef();
  const domicileCostRef = useRef();
  const cargoManifestRef = useRef();
  const defaultRef = useRef();

  const inputRefs = [
    serviceTypeRef, 
    weightAmountRef, 
    typePackageRef, 
    contentPackageRef, 
    declaredValueRef, 
    costOrderRef, 
    quantityRef, 
    domicileCostRef,
    cargoManifestRef, 
    defaultRef
  ];

  useEffect(() => {
    let touchesValues = {
      paymentMethod: orderingData?.paymentMethod ? true : false,
      typePackage: orderingData?.typePackage ? true : false,
      contentPackage: orderingData?.contentPackage ? true : false,
      declaredValue: orderingData?.declaredValue ? true : false,
      domicile: orderingData?.domicile ? true : false,
      weightAmount: orderingData?.weightAmount ? true : false,
      serviceType: orderingData?.serviceType ? true : false,
      costOrder: orderingData?.costOrder ? true : false,
      quantity: orderingData?.quantity ? true : false,
      cargoManifest: orderingData?.cargoManifest ? true : false,
      insuranceCost: orderingData?.insuranceCost ? true : false,
      vehicleSelected: orderingData?.vehicleSelected ? true : false,
      suggestedCost: orderingData?.suggestedCost ? true : false,
      domicileCost: orderingData?.domicileCost ? true : false,
      domicileDescription: orderingData?.domicileDescription ? true : false
    }
    setTouched({...touched,...touchesValues});
    setValues(orderingData);
    dataSelectorsForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

    const dataSelectorsForm = async () => {
      
    await Promise.allSettled([
      getAllShippingTypeService(),
      getAllPaymentMethodService(),
      getServiceTypeService(),
    ])
    .then((res)=>{
      let paymentMethodList = [];
      let serviceTypeList = [];
      let shippingOthers = undefined;

      for (let index = 0; index < res.length; index++) {
        const elementResponse = res[index];

        if (elementResponse.status === AppConst.FULFILLED) {
          
          switch (index) {
            case 0:
              shippingOthers = elementResponse?.value?.shippingIdOthers
              break;
            case 1:
              paymentMethodList = (elementResponse?.value || [])
                .filter(paymentMethod => paymentMethod.name !== "Crédito" && paymentMethod.name !== "Transferencia")
                .map(({ name, id }) => ({ label: name, value: id }));
                if(orderingData?.paymentMethod === undefined) {
                  setDataSalesStateReducer("shippingsSales", "paymentMethod", paymentMethodList[0]?.value);
                  setValues({ ...values, paymentMethod: paymentMethodList[0]?.value })
                }
              break;
            case 2:
              serviceTypeList = elementResponse?.value?.map((serviceType) => ({ label: serviceType?.name, value: serviceType?.id }))
              break;
            default:
              break;
          }
        }
      }
      
      setState({
        ...state,
        optionsPaymentMethod: paymentMethodList,
        optionsServiceType: serviceTypeList,
        shippingOthers: shippingOthers
      })
    })
    
    .catch(() => {
      // TODO: Implement error alert with code error.
    })
  } 

  const { values, errors, touched, handleBlur, setValues, setTouched, setFieldValue } = useFormik({
    initialValues: {
      paymentMethod: undefined,
      typePackage: undefined,
      contentPackage: "",
      declaredValue: 0,
      domicile: false,
      serviceType: undefined,
      weightAmount: 0,
      isCalculate: true,
      costOrder: 0,
      insuranceCost: 0,
      totalOrder: 0,
      vehicleSelected: undefined,
      suggestedCost: 0,
      domicileCost: 0,
      domicileDescription: "",
      quantity: 0,
      cargoManifest: 0
    },
    validationSchema: shippingsOrderDataSchema,
  });

  useEffect(() => {
    setStateSaleReducer("isError", !!Object.keys(errors).length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  const onChange = async(data, target) => {
    let value = data && data.target 
      ? data.target?.value 
        ? data.target.value 
        : data.target?.checked 
          ? data.target.checked 
          : ""
      : data;
    let valuesForm = { ...values }
    let valuesReducer = {}
    if (
      target === "declaredValue" || target === "weightAmount" || target === "costOrder" ||
      target === "insuranceCost" || target === "totalOrder" || target === "domicileCost" || target === "quantity" || target === "cargoManifest"
    ) {
      value = value.replace(/,/g, "");
      value = value.replace(/[^0-9]/g, '')
      if (/^-?\d*\.?\d*$/.test(value) || value === '' || value === '-') {
        value = Number(value)
        if (target === "declaredValue" || target === "weightAmount") {
          valuesForm[target] = value
          const valueResult = await valueCalculations({target, value})
          if (target === "declaredValue") {
            valuesForm.insuranceCost = valueResult
            valuesReducer.insuranceCost = valueResult
          } 
          if (target === "weightAmount"){
            valuesForm.suggestedCost = valueResult
            valuesReducer.suggestedCost = valueResult
          }
        }else{
          valuesForm[target] = value
        }
      }
    }else{
      if (target === "serviceType") {
        const constData = await getConstantSystemService(SalesConst.MINIUM_DECLARED_VALUE)
        let touchesValues = { ...touched, declaredValue: true };
        if (value?.label === 'Peso') {
          valuesForm[target] = value
          valuesForm.weightAmount = 0
          valuesForm.suggestedCost = 0
          touchesValues.weightAmount = false ;
          setState(prevState => ({
            ...prevState,
            SelectedMessage: `(mayor ó igual que $${Number(constData?.value).toLocaleString()})`
          }));
        }
        else if (value?.label === "Sobre") {
          setState(prevState => ({
            ...prevState,
            SelectedMessage: ""
          }));
          valuesForm[target] = value
        }
        else {
          setState(prevState => ({
            ...prevState,
            SelectedMessage: `(mayor ó igual que $${Number(constData?.value).toLocaleString()})`
          }));
          valuesForm[target] = value
        }
        setTouched(touchesValues);
      }else{
        if(target === "domicile"){
          setTouched({ ...touched, domicileCost: true, domicileDescription: true })
          valuesForm.domicileCost = 0
          valuesForm.domicileDescription = ""
          valuesReducer.domicileCost = 0
          valuesReducer.domicileDescription = ""
        }
        valuesForm[target] = value
      }
    }
    if (target === "paymentMethod"){
      valuesReducer.paymentMethod = value
    }
    const {
      costOrder,
      insuranceCost,
      domicileCost,
      quantity,
      cargoManifest
    } = valuesForm

    const totalOrder = (costOrder + insuranceCost + domicileCost + quantity + cargoManifest) || 0
    valuesForm.totalOrder = totalOrder
    valuesReducer.totalOrder = totalOrder

    setValues(valuesForm);
    valuesReducer[target] = value
    setDataSaleReducer("shippingsSales", valuesReducer)
  };

  const constantsValue = async(constData) => {
    try {
      return await getConstantSystemService(constData) 
    } catch (error) {
      // TODO: Implement error alert with code error.
    }
  }

  const valueCalculations = async({target, value}) =>{
    switch (target) {
      case "declaredValue":
        const costValueConstants =  await constantsValue(SalesConst.DECLARED_VALUE)
        const newInsuranceCost = Math.ceil(value * costValueConstants?.value)
        return newInsuranceCost
        // setDataSalesStateReducer("shippingsSales", "insuranceCost", newInsuranceCost);

      case "weightAmount":
        const costWeightConstants =  await constantsValue(SalesConst.COST_PER_KILO)
        const newSuggestedCost = Math.ceil(value * costWeightConstants?.value)
        return newSuggestedCost
        // setDataSalesStateReducer("shippingsSales", "suggestedCost", newSuggestedCost);
    
      default:
        break;
    }
  }

  const handleSearchVehicles = async(value) =>{
    try {
      const filter = {
        date: dayjs().format('YYYY-MM-DD'),
        time: dayjs().format('HH:mm:ss'),
        internalNumber: value
      }
      const optionVehicles = await getAllVehiclesAvailableTravelService(filter)
      setState({
        ...state,
        optionsVehicles : optionVehicles
      })
    } catch (error) {
      // TODO: Implement error alert with code error.
    }
  }

  const handleTypePackage = async(value) =>{
    try {
      const shippingTypeList = await getAllShippingTypeService(value)
      const shippingType = shippingTypeList?.shippingTypeList.map((shippingType) => ({ label: shippingType?.name, value: shippingType?.id }))
      setState({
        ...state,
        optionsShippingType : shippingType
      })
    } catch (error) {
      // TODO: Implement error alert with code error.
    }
  }

  return (
    <div className="grid-x">
      <div className="small-12 ordering-data__content flex-container align-middle">
        <label className="ordering-data__title">
        Ingresa los datos de tu encomienda
        </label>
      </div>
      <div className="grid-x small-6 ordering-data__content__separator">
        <div className="small-12 ordering-data__content ordering-data__content--mod">
          <label className="grid-x ordering-data__label align-items-center">
            {t("sales.shippings.orderingData.payment")}
            <div className="ordering-data__label__icon"/>
          </label>
          <div className='grid- ordering-data__content-check'>
            {optionsPaymentMethod.map((method) => (
              <div className="ordering-data__content-check__check" key={method.value}>
                {method.label}
                <Checkbox
                  disabled={isDisabled}
                  checked={values.paymentMethod === method.value}
                  onChange={(e) => onChange(method.value, "paymentMethod")}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="small-5 ordering-data__content">
          <label className="grid-x ordering-data__label ordering-data__label--mod">
            Tipo de paquete
            <div className="ordering-data__label__icon"/>
          </label>
          <Select
            ref={inputRefs[0]}
            disabled={isDisabled}
            value={values.serviceType}
            className="ordering-data__select"
            onChange={(e, event) => onChange(event, "serviceType")}
            onBlur={(value) => handleBlur("serviceType")(value)}
            status={errors.serviceType && touched.serviceType ? "error" : ""}
            suffixIcon={
              <img
                src={Assets.SharedIcons.icon_down_arrow}
                alt="icon_down_arrow"
              />
            }
            options={optionsServiceType}
            placeholder="Tipo de paquete"
            onDropdownVisibleChange={(open) => setState({...state, isDropdownOpen: open})}
            onSelect={(value, data) => {
              setTimeout(() => {
                if (isDropdownOpen) {
                  if (data?.label === "Peso") {
                    inputRefs[1].current.focus();
                  } else {
                    inputRefs[2].current.focus();
                  }
                }
              }, 100);
            }}
          />
          <span className="grid-x ordering-data__errors">
            {touched.serviceType ? errors.serviceType : null}
          </span>
        </div>
        <div className="grid-x small-7 ordering-data__content">
          <label className="grid-x ordering-data__label">
            {t("sales.shippings.orderingData.typeOrder")}
            <div className="ordering-data__label__icon"/>
          </label>
          <AutoComplete
            ref={inputRefs[2]}
            disabled={isDisabled}
            className="small-11"
            status={errors.typePackage && touched.typePackage ? "error" : ""}
            onSearch={(e) => {
              const trimmedValue = e.trim();
              if (trimmedValue) {
                handleTypePackage(trimmedValue);
              } else {
                onChange(undefined, "typePackage")
                setState({
                  ...state,
                  optionsShippingType: []
                })
              }
            }}
            onChange={(value) => onChange({ label: value, value: shippingOthers, type: "others" },"typePackage")}
            onSelect={(e,value) => onChange(value, "typePackage")}
            options={optionsShippingType}
            value={values?.typePackage?.label}
            onBlur={(value) => handleBlur("typePackage")(value)}
            popupMatchSelectWidth={false}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                inputRefs[3].current.focus();
              }
            }}
          >
            <Input
              allowClear={{
                clearIcon: (
                <img
                    width={28}
                    src={Assets.SharedIcons.icon_square_close}
                    alt="icon_logo_vertical"
                />
                )
              }}
              className="modal-transfer__input__content"
              placeholder={t("sales.shippings.orderingData.selectTypeOrder")}
              prefix={
                <img
                  className="modal-transfer__input__search"
                  src={Assets.SharedIcons.icon_search}
                  alt="icon_search"
                />
              }
            />
          </AutoComplete>
          <span className="grid-x ordering-data__errors">
            {touched.typePackage ? errors.typePackage : null}
          </span>
        </div>
        <div className="small-12 ordering-data__content">
          <label className=" grid-x ordering-data__label">
            {t("sales.shippings.orderingData.containedOrder")} (Opcional)
          </label>
          <Input
            ref={inputRefs[3]}
            placeholder={t("sales.shippings.orderingData.containedOrder")}
            disabled={isDisabled}
            value={values.contentPackage}
            onChange={(value) => onChange(value, "contentPackage")}
            onBlur={(value) => handleBlur("contentPackage")(value)}
            status={errors.contentPackage && touched.contentPackage ? "error" : ""}
            className="ordering-data__input__content"
            allowClear={{
              clearIcon: (
                <img
                  width={28}
                  src={Assets.SharedIcons.icon_square_close}
                  alt="icon_logo_vertical"
                />
              ),
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                inputRefs[4].current.focus();
              }
            }}
          />
          <span className="grid-x ordering-data__errors">
            {touched.contentPackage ? errors.contentPackage : null}
          </span>
        </div>
        <div className="small-12 ordering-data__content">
          <label className=" grid-x ordering-data__label">
            {t("sales.shippings.orderingData.declaredValue")}
            <div className="ordering-data__label__icon"/>
            {SelectedMessage}
          </label>
          <div className="grid-x">
            <Input
                ref={inputRefs[4]}
                disabled={isDisabled}
                value={values.declaredValue?.toLocaleString()}
                onChange={(value) => onChange(value, "declaredValue")}
                onBlur={(value) => handleBlur("declaredValue")(value)}
                status={errors.declaredValue && touched.declaredValue ? "error" : ""}
                className="ordering-data__input__content ordering-data__input__content--declared"
                allowClear={{
                clearIcon: (
                    <img
                    width={28}
                    src={Assets.SharedIcons.icon_square_close}
                    alt="icon_logo_vertical"
                    />
                ),
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    inputRefs[5].current.focus();
                  }
                }}
            />
            <label className="grid-x align-center-middle ordering-data__label">
                COP
            </label>
          </div>
          <span className="grid-x ordering-data__errors">
            {touched.declaredValue ? errors.declaredValue : null}
          </span>
        </div>
        <div className="small-12 ordering-data__content ordering-data__content--mod">
          <label className="grid-x ordering-data__label align-items-center">
            {t("sales.shippings.orderingData.userAddress")}
            <div className="ordering-data__label__icon"/>
          </label>
          <div className='grid- ordering-data__content-check'>
            <div className="ordering-data__content-check__check">
              No
              <Checkbox
                disabled={isDisabled}
                checked={values.domicile === false ? true : false}
                onChange={(e) => onChange(!values.domicile, "domicile")}
              />
            </div>
            <div className="ordering-data__content-check__check">
              Sí
              <Checkbox
                disabled={isDisabled}
                checked={values.domicile === false ? false : true}
                onChange={(e) => onChange(!values.domicile, "domicile")}
              />
            </div>
          </div>
        </div>
        { values.domicile === true ?
          <div className="small-12 ordering-data__content">
            <label className="grid-x ordering-data__label">
              Descripción del domicilio (Opcional)
            </label>
            <Input.TextArea
              autoFocus={values.domicile === true}
              disabled={isDisabled}
              value={values.domicileDescription}
              onChange={(value) => onChange(value, "domicileDescription")}
              onBlur={(value) =>handleBlur("domicileDescription")(value)}
              status={errors.domicileDescription && touched.domicileDescription ? "error" : ""}
              style={{
                resize: "none",
                width: "95%",
              }}
              rows={3} 
              placeholder="Descripción" 
              maxLength={250}
              allowClear={{
                clearIcon: (
                    <img
                    width={28}
                    src={Assets.SharedIcons.icon_square_close}
                    alt="icon_logo_vertical"
                    />
                ),
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  inputRefs[5].current.focus();
                }
              }}
            />
            <span className="grid-x ordering-data__errors">
              {touched.domicileDescription ? errors.domicileDescription : null}
            </span>
          </div>
          : null
        }
      </div>
      <div className="small-6 ordering-data__content__separator">
        { values.serviceType?.label === "Peso" &&
          <div className="grid-x ordering-data__content__right">
            <div style={{ width: "95%" }} className="grid-x">
              <div className="grid-x small-6 ordering-data__content">
                  <label className="grid-x ordering-data__label ordering-data__label--mod">
                    {t("sales.shippings.orderingData.weightOrder")}
                    <div className="ordering-data__label__icon"/>
                  </label>
                  <div className="grid-x ordering-data__label--mod">
                      <Input
                        ref={inputRefs[1]}
                        style={{ width: "80%" }}
                        disabled={isDisabled}
                        value={values.weightAmount?.toLocaleString()}
                        onChange={(value) => onChange(value, "weightAmount")}
                        onBlur={(value) =>{
                          onChange(values.suggestedCost?.toLocaleString(), "costOrder")
                          handleBlur("weightAmount")(value)
                        }}
                        status={errors.weightAmount ? "error" : ""}
                        className="ordering-data__input__content"
                        allowClear={{
                        clearIcon: (
                          <img
                            width={28}
                            src={Assets.SharedIcons.icon_square_close}
                            alt="icon_logo_vertical"
                          />
                        ),
                        }}
                        placeholder={t("sales.shippings.orderingData.quantity")}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            e.preventDefault();
                            inputRefs[2].current.focus();
                          }
                        }}
                      />
                      <label className="grid-x align-center-middle ordering-data__label">
                        Kg
                      </label>
                      <span className="grid-x ordering-data__errors justify-content-start">
                          {errors.weightAmount ? errors.weightAmount : null}
                      </span>
                  </div>
              </div>
              <div className="grid-x small-6 ordering-data__content">
                  <label className="grid-x ordering-data__label ordering-data__label--mod">
                    Costo sugerido por peso
                  </label>
                  <Input
                    disabled
                    value={values.suggestedCost?.toLocaleString()}
                    className="small-12 ordering-data__input__content"
                    allowClear={{
                    clearIcon: (
                        <img
                        width={28}
                        src={Assets.SharedIcons.icon_square_close}
                        alt="icon_logo_vertical"
                        />
                    ),
                    }}
                    placeholder={t("sales.shippings.orderingData.quantity")}
                  />
              </div>
            </div>
          </div>
        }
        <div className="ordering-data__content__right" style={{ width: "100%" }}>
          <label className="grid-x ordering-data__label ordering-data__label--mod">
            Busca vehículos Disponible
          </label>
          <AutoComplete
            autoFocus
            disabled={isDisabled}
            style={{ width: "95%" }}
            onSearch={(e) => {
              const trimmedValue = e.trim();
              if (trimmedValue) {
                handleSearchVehicles(trimmedValue);
              } else {
                onChange(undefined, "vehicleSelected")
                setState({
                  ...state,
                  optionsVehicles: []
                })
              }
            }}
            onChange={(value) => onChange({ label: value },"vehicleSelected")}
            onSelect={(e,value) => onChange(value, "vehicleSelected")}
            onBlur={() =>{ 
              !values?.vehicleSelected?.value && 
                setFieldValue("vehicleSelected", undefined)
                setState({
                  ...state,
                  optionsVehicles: []
                })
            }}
            options={optionsVehicles}
            value={values?.vehicleSelected?.label}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                inputRefs[0].current.focus();
              }
            }}
          >
            <Input
              allowClear={{
                clearIcon: (
                <img
                    width={28}
                    src={Assets.SharedIcons.icon_square_close}
                    alt="icon_logo_vertical"
                />
                )
              }}
              className="modal-transfer__input__content"
              placeholder="Buscar vehículo"
              prefix={
                <img
                  className="modal-transfer__input__search"
                  src={Assets.SharedIcons.icon_search}
                  alt="icon_search"
                />
              }
            />
          </AutoComplete>
        </div>
        <div className="ordering-data__content__right ordering-data__content__separator">
            <div className="grid-x ordering-data__label--mod ordering-data__box">
                { values.isCalculate ?
                    <>
                        <div className="grid-x small-12 align-middle justify-content-evenly" style={{ marginTop: "15px"}}>
                            <label className="grid-x ordering-data__label small-7">
                              {t("sales.shippings.orderingData.costOrder")}
                              <div className="ordering-data__label__icon"/>
                            </label>
                            <Input
                                ref={inputRefs[5]}
                                disabled={isDisabled}
                                value={values.costOrder?.toLocaleString()}
                                onChange={(value) => onChange(value, "costOrder")}
                                onBlur={(value) => handleBlur("costOrder")(value)}
                                status={errors.costOrder && touched.costOrder ? "error" : ""}
                                className="small-4 ordering-data__input__content"
                                allowClear={{
                                clearIcon: (
                                    <img
                                    width={28}
                                    src={Assets.SharedIcons.icon_square_close}
                                    alt="icon_logo_vertical"
                                    />
                                ),
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter') {
                                    e.preventDefault();
                                    inputRefs[6].current.focus();
                                  }
                                }}
                            />
                            <span className="grid-x ordering-data__errors justify-content-end">
                              {touched.costOrder ? errors.costOrder : null}
                            </span>
                        </div>
                        <div className="grid-x small-12 align-middle justify-content-evenly">
                            <label className="grid-x ordering-data__label small-7">
                              Porte
                              <div className="ordering-data__label__icon"/>
                            </label>
                            <Input
                                ref={inputRefs[6]}
                                disabled={isDisabled}
                                value={values.quantity?.toLocaleString()}
                                onChange={(value) => onChange(value, "quantity")}
                                onBlur={(value) => handleBlur("quantity")(value)}
                                status={errors.quantity && touched.quantity ? "error" : ""}
                                className="small-4 ordering-data__input__content"
                                allowClear={{
                                clearIcon: (
                                    <img
                                    width={28}
                                    src={Assets.SharedIcons.icon_square_close}
                                    alt="icon_logo_vertical"
                                    />
                                ),
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter') {
                                    e.preventDefault();
                                    if (values.domicile === true) {
                                      inputRefs[7].current.focus();
                                    } else {
                                      inputRefs[8].current.focus();
                                    }
                                  }
                                }}
                            />
                            <span className="grid-x ordering-data__errors justify-content-end">
                              {touched.quantity ? errors.quantity : null}
                            </span>
                        </div>
                        <div className="grid-x small-12 align-middle justify-content-evenly">
                            <label className="grid-x ordering-data__label small-7">
                              {t("sales.shippings.orderingData.costInsurance")}
                              <div className="ordering-data__label__icon"/>
                            </label>
                            <Input
                                disabled
                                value={values.insuranceCost?.toLocaleString()}
                                onChange={(value) => onChange(value, "insuranceCost")}
                                onBlur={(value) => handleBlur("insuranceCost")(value)}
                                status={errors.insuranceCost && touched.insuranceCost ? "error" : ""}
                                className="small-4 ordering-data__input__content"
                                allowClear={{
                                clearIcon: (
                                    <img
                                    width={28}
                                    src={Assets.SharedIcons.icon_square_close}
                                    alt="icon_logo_vertical"
                                    />
                                ),
                                }}
                            />
                            <span className="grid-x ordering-data__errors justify-content-end">
                              {touched.insuranceCost ? errors.insuranceCost : null}
                            </span>
                        </div>
                        { values.domicile === true ?
                          <div className="grid-x small-12 align-middle justify-content-evenly">
                              <label className="grid-x ordering-data__label small-7">
                                Costo del domicilio
                                <div className="ordering-data__label__icon"/>
                              </label>
                              <Input
                                  ref={inputRefs[7]}
                                  disabled={isDisabled}
                                  value={values.domicileCost?.toLocaleString()}
                                  onChange={(value) => onChange(value, "domicileCost")}
                                  onBlur={(value) => handleBlur("domicileCost")(value)}
                                  status={errors.domicileCost && touched.domicileCost ? "error" : ""}
                                  className="small-4 ordering-data__input__content"
                                  allowClear={{
                                  clearIcon: (
                                      <img
                                      width={28}
                                      src={Assets.SharedIcons.icon_square_close}
                                      alt="icon_logo_vertical"
                                      />
                                  ),
                                  }}
                                  onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                      e.preventDefault();
                                      inputRefs[8].current.focus();
                                    }
                                  }}
                              />
                              <span className="grid-x ordering-data__errors justify-content-end">
                                {touched.domicileCost ? errors.domicileCost : null}
                              </span>
                          </div>
                          : null
                        }
                        <div className="grid-x small-12 align-middle justify-content-evenly">
                            <label className="grid-x ordering-data__label small-7">
                              Manifiesto de carga
                              <div className="ordering-data__label__icon"/>
                            </label>
                            <Input
                                ref={inputRefs[8]}
                                disabled={isDisabled}
                                value={values.cargoManifest?.toLocaleString()}
                                onChange={(value) => onChange(value, "cargoManifest")}
                                onBlur={(value) => handleBlur("cargoManifest")(value)}
                                status={errors.cargoManifest && touched.cargoManifest ? "error" : ""}
                                className="small-4 ordering-data__input__content"
                                allowClear={{
                                clearIcon: (
                                    <img
                                    width={28}
                                    src={Assets.SharedIcons.icon_square_close}
                                    alt="icon_logo_vertical"
                                    />
                                ),
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter') {
                                    e.preventDefault();
                                    nextButtonRef.current.focus();
                                  }
                                }}
                            />
                            <span className="grid-x ordering-data__errors justify-content-end">
                              {touched.cargoManifest ? errors.cargoManifest : null}
                            </span>
                        </div>
                    </>
                    :
                    <>
                        <span className="grid-x  align-middle small-12 text-center ordering-data__text ordering-data__text--mod">
                          {t("sales.shippings.orderingData.buttonCalculate")}
                        </span>
                    </>
                }
            </div>
        </div>
        { values.isCalculate &&
            <div className="small-12 ordering-data__content__right ordering-data__content__separator">
              <div className="grid-x ordering-data__label--mod align-middle">
                <label className="small-6 ordering-data__label ordering-data__label--total">
                  {t("sales.shippings.orderingData.totalOrder")}
                </label>
                <Input
                    disabled
                    value={values.totalOrder?.toLocaleString()}
                    onChange={(value) => onChange(value, "totalOrder")}
                    onBlur={(value) => handleBlur("totalOrder")(value)}
                    status={errors.totalOrder && touched.totalOrder ? "error" : ""}
                    className="small-6 ordering-data__input__content"
                    allowClear={{
                    clearIcon: (
                        <img
                        width={28}
                        src={Assets.SharedIcons.icon_square_close}
                        alt="icon_logo_vertical"
                        />
                    ),
                    }}
                    placeholder={t("sales.passages.clientData.enterName")}
                />
                <span className="grid-x ordering-data__errors justify-content-end">
                    {
                      touched.costOrder || 
                      touched.cargoManifest || 
                      touched.domicileCost || 
                      touched.quantity
                      ? errors.totalOrder : null
                    }
                </span>
              </div>
            </div>
        }
      </div>
    </div>
  );
};

const mapStateToProps = ({ SalesReducer }) => {
  const {
    shippingsSales: {
      paymentMethod,
      typePackage,
      contentPackage,
      declaredValue,
      domicile,
      serviceType,
      weightAmount,
      isCalculate,
      costOrder,
      quantity,
      cargoManifest,
      insuranceCost,
      totalOrder,
      vehicleSelected,
      suggestedCost,
      domicileCost,
      domicileDescription
    },
    isDisabled,
  } = SalesReducer;
  return {
    paymentMethod,
    typePackage,
    contentPackage,
    declaredValue,
    domicile,
    serviceType,
    weightAmount,
    isCalculate,
    costOrder,
    quantity,
    cargoManifest,
    insuranceCost,
    totalOrder,
    isDisabled,
    vehicleSelected,
    suggestedCost,
    domicileCost,
    domicileDescription
  };
};

const mapStateToPropsActions = {
  setDataSalesStateReducer,
  setStateSaleReducer,
  setDataSaleReducer
};

export default connect( mapStateToProps , mapStateToPropsActions )(OrderingDataComponent);
